// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */

h1 {
    display: flex;
    align-items: center;
  }


img {
    max-width: 35%;
    height: auto;
  }
  
  @media screen and (max-width: 600px) {
    img {
      max-width: 50%;
    }
    h1 {
        display: block;
      }
  }
  `, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,aAAa;IACb,mBAAmB;EACrB;;;AAGF;IACI,cAAc;IACd,YAAY;EACd;;EAEA;IACE;MACE,cAAc;IAChB;IACA;QACI,cAAc;MAChB;EACJ","sourcesContent":["/* styles.css */\n\nh1 {\n    display: flex;\n    align-items: center;\n  }\n\n\nimg {\n    max-width: 35%;\n    height: auto;\n  }\n  \n  @media screen and (max-width: 600px) {\n    img {\n      max-width: 50%;\n    }\n    h1 {\n        display: block;\n      }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
