// App.js

import { FooterDivider } from 'flowbite-react';
import React from 'react';
import AzureUploader from './AzureUploader';

import './styles.css';
import superpeLogo from './superpe-logo.png'; // Replace with the actual path to your image file

function App() {
  const searchParams = new URLSearchParams(window.location.search);
  const params = searchParams.get('merchant_id')?.split(',');
  const cardInfo = searchParams.get('card_info')?.split(',');
  const part = searchParams.get('part') ? Number(searchParams.get('part')) : 1;
  // console.log('// part', part, searchParams.get('part'));
  return (
    <div className='App font-superFont max-w-screen-sm'>
      <div className='flex flex-col w-100 p-5 items-center'>
        <div className='block p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700'>
          <div className='no-wrap flex w-100 flex-row my-2'>
            <img
              className='h-auto max-w-10 w-10 aspect-square rounded-lg mr-2'
              src={superpeLogo}
              alt='Superpe Logo'
            />
            <h5 className='mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white'>
              SuperPe Verification ✦
            </h5>
          </div>
          <FooterDivider />
          {part <= 2 && (
            <div className='my-5'>
              {/* <h2 className='mb-2 text-lg font-semibold text-gray-900 dark:text-white'>
                Documents to be verified:
              </h2> */}
              <ul className='max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400'>
                {/* {(params?.includes('pan') || params?.includes('aadhaar')) && (
                  <li className='flex items-center'>
                    <svg
                      className='w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='currentColor'
                      viewBox='0 0 20 20'
                    >
                      <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
                    </svg>
                    For KYC, please share your{' '}
                    {params?.includes('pan')
                      ? params?.includes('aadhaar')
                        ? 'Aadhar & PAN'
                        : 'PAN'
                      : ''}{' '}
                    card.
                  </li>
                )} */}

                {/* <li className='flex items-center'>
                  <svg
                    className='w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                  >
                    <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
                  </svg>
                  Share your credit card photo where last 4 digits of your card,
                  & your name is visible.
                  {!cardInfo && 'Or, last available Card Statement.'}
                </li> */}

                {/* {!cardInfo && (
                  <li className='flex items-center'>
                    <svg
                      className='w-3.5 h-3.5 me-2 text-gray-500 dark:text-gray-400 flex-shrink-0'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='currentColor'
                      viewBox='0 0 20 20'
                    >
                      <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
                    </svg>
                    In case of your family member's card, their KYC, & Card
                    statement/Photo, are must.
                  </li>
                )} */}
              </ul>
            </div>
          )}

          {part <= 2 && cardInfo && cardInfo.length > 2 && (
            <div className='w-full max-w-sm bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 overflow-hidden'>
              <div className='text-md font-bold  p-2 bg-gray-900 text-white'>
                <h3>Card selected for verification</h3>
              </div>
              <div className='flex flex-row border-b-2 border-b-gray-200 p-2'>
                <div className='flex flex-1 items-center'>
                  <p>Last 4 digits </p>
                </div>
                <div className='flex flex-1 items-center'>
                  <b>{cardInfo[0]}</b>
                </div>
              </div>
              <div className='flex flex-row border-b-2 border-b-gray-200 p-2'>
                <div className='flex flex-1 items-center'>
                  <p>Bank name</p>
                </div>
                <div className='flex flex-1 items-center'>
                  <b>{cardInfo[1]}</b>
                </div>
              </div>
              <div className='flex flex-row border-b-2 border-b-gray-200 p-2'>
                <div className='flex flex-1 items-center'>
                  <p>Network</p>
                </div>
                <div className='flex flex-1 items-center'>
                  <b>{cardInfo[2]}</b>
                </div>
              </div>
            </div>
          )}
          <div className='my-6'>
            {part <= 2 ? (
              <AzureUploader cardInfo={cardInfo} part={part} />
            ) : (
              <>
                <div
                  id='toast-success'
                  className='flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800'
                  role='alert'
                >
                  <div className='inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200'>
                    <svg
                      className='w-5 h-5'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='currentColor'
                      viewBox='0 0 20 20'
                    >
                      <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
                    </svg>
                    <span className='sr-only'>Check icon</span>
                  </div>
                  <div className='ms-3 text-sm font-normal'>
                    Document uploaded successfully. You can close this page or{' '}
                    <u>call us to expedite</u>.
                  </div>
                </div>

                <a
                  type='button'
                  className={`text-white w-full bg-blue-700 hover:bg-blue-800
               focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-3.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 my-4 flex flex-row items-center justify-center`}
                  href=''
                  onClick={() => {
                    window.ReactNativeWebView.postMessage('tel:+919000400804');
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#fff'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <path d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'></path>
                  </svg>
                  <span className='text-lg mx-4'>Call us</span>
                </a>
              </>
            )}
          </div>
          {part <= 2 && cardInfo && (
            <div className='w-full bg-white border border-black rounded-lg overflow-hidden'>
              <div className='text-sm font-bold  p-2 bg-gray-900 text-white'>
                Why are we doing this?
              </div>
              <div className='text-sm  p-2'>
                By verifying your card we ensure that your card is used by only
                you on SuperPe.{' '}
                <u>We assure you of super safe & secure payments</u>. Once
                verified, your transaction will settle immediately.
              </div>
            </div>
          )}
        </div>
        <blockquote className='p-4 my-4 border-s-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800'>
          <p className='text-xs italic font-small leading-relaxed text-gray-900 dark:text-white'>
            <b>SuperPe</b> never ask for your OTP, PIN, password or any login
            related information. This information portal is only to verify the
            ownership of SuperPe account & card used. <br />
            We may ask the password to verify your credit card PDF statements.
          </p>
        </blockquote>
      </div>
    </div>
  );
}

export default App;
