// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */

.container {
    margin: 0;
    padding: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .upload-container {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  input {
    margin-bottom: 10px;
  }
  
  button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/uploader.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,SAAS;IACT,UAAU;IACV,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* styles.css */\n\n.container {\n    margin: 0;\n    padding: 0;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n  }\n  \n  .upload-container {\n    margin-top: auto;\n    margin-bottom: auto;\n  }\n  \n  input {\n    margin-bottom: 10px;\n  }\n  \n  button {\n    padding: 10px;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    cursor: pointer;\n  }\n  \n  button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
